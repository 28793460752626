import { Types } from 'mongoose';

export enum ESuggestionType {
  OPPORTUNITY = 'OPPORTUNITY',
  SOLUTION = 'SOLUTION'
}

export enum ESuggestionStatus {
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  IN_QUEUE = 'IN_QUEUE',
  IN_PROGRESS = 'IN_PROGRESS',
  RESOLVED = 'RESOLVED',
  ARCHIVED = 'ARCHIVED'
}

export interface ISuggestion {
  type: ESuggestionType;
  status: ESuggestionStatus;
  tenant: Types.ObjectId;
  author?: Types.ObjectId;
  authorName: string;
  authorTaskPerformance?: Types.ObjectId;
  dateCreated: Date;
  title: string;
  body: string;
  attachments: Types.ObjectId[];
  isPublic: boolean;
  notes: string;
  parent: Types.ObjectId;
}
