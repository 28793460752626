import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChangeEmailComponent } from './change-email.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: ChangeEmailComponent
      }
    ])
  ],
  declarations: [ChangeEmailComponent]
})
export class ChangeEmailModule {}
