<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-title>Team Settings</ion-title>
    <ion-buttons slot="start">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content #content>
  <app-width-restricted-container class="container-with-fab">
    <ion-accordion-group
      class="team-settings-accordion"
      [value]="accordionValue"
      (ionChange)="onAccordionValueChange($event)"
    >
      <ion-accordion value="details" id="details">
        <ion-item slot="header" appHaptics class="team-settings-item">
          Details
        </ion-item>
        <ion-grid slot="content" class="team-settings-item-content">
          <ion-row>
            <ion-col size="12">
              <ion-input
                label="Company Name"
                class="app-w100 input-with-background"
                type="text"
                [ngModel]="companyName"
                [ngModelOptions]="{ standalone: true }"
                [debounce]="700"
                (ionInput)="onCompanyInput($any($event))"
                labelPlacement="floating"
                fill="solid"
                required
                autocapitalize="on"
                [spellcheck]="true"
              />
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-input
                id="selectTimezone"
                label="Team Timezone"
                class="app-w100 input-with-background"
                labelPlacement="floating"
                fill="solid"
                [readonly]="true"
                [ngModel]="timezone"
                appHaptics
              />
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-select
                labelPlacement="floating"
                fill="solid"
                aria-label="After Login, User Goes to"
                interface="popover"
                label="After Login, User Goes to"
                class="app-w100 input-with-background select-round-fix"
                [value]="loginRedirectsTo"
                (ionChange)="onLoginRedirectsToChange($any($event))"
                appHaptics
              >
                <ion-select-option value="/i/recognition">
                  Recognition
                </ion-select-option>
                <ion-select-option
                  value="/i/checklist"
                  *ngIf="tenantFeatures.checklist"
                >
                  Checklist
                </ion-select-option>
                <ion-select-option value="/i/tags" *ngIf="tenantFeatures.tags">
                  Tags
                </ion-select-option>
                <ion-select-option
                  value="/i/announcements"
                  *ngIf="tenantFeatures.announcements"
                >
                  Announcements
                </ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-accordion>

      <ion-accordion value="payroll" id="payroll">
        <ion-item slot="header" appHaptics class="team-settings-item">
          Compensation
        </ion-item>
        <ion-grid slot="content" class="team-settings-item-content">
          <ion-row>
            <ion-col size="12">
              <ion-select
                labelPlacement="floating"
                fill="solid"
                aria-label="Pay Frequency"
                interface="popover"
                label="Pay Frequency"
                class="app-w100 input-with-background select-round-fix"
                [value]="frequency"
                (ionChange)="onFrequencyChange($any($event))"
                appHaptics
              >
                <ion-select-option [value]="payFrequency.EVERY_WEEK">
                  Every week
                </ion-select-option>
                <ion-select-option [value]="payFrequency.EVERY_TWO_WEEKS">
                  Every two weeks
                </ion-select-option>
                <ion-select-option [value]="payFrequency.ONCE_PER_MONTH">
                  Once per month
                </ion-select-option>
                <ion-select-option [value]="payFrequency.TWICE_PER_MONTH">
                  Twice per month
                </ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="date1">
            <ion-col size="12">
              <app-date-form-control
                label="Next pay-period begins on"
                [(ngModel)]="date1"
                [dateTransformer]="frequency | dateTransformer"
                (ngModelChange)="onDateChange()"
              >
              </app-date-form-control>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="date2">
            <ion-col size="12">
              <app-date-form-control
                label="Next pay-period begins on"
                [(ngModel)]="date2"
                [dateTransformer]="frequency | dateTransformer"
                (ngModelChange)="onDateChange()"
              >
              </app-date-form-control>
            </ion-col>
          </ion-row>

          <div *ngIf="tenantFeatures.calculateRewards">
            <ion-row>
              <ion-col class="app-notice">
                <strong>Compensation Pools</strong>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <div class="compensation-pools">
                  <ion-chip
                    class="compensation-pool"
                    *ngFor="
                      let compensationPool of compensationPools;
                      trackBy: trackCompensationPools
                    "
                    (click)="updateCompensationPool(compensationPool)"
                    appHaptics
                  >
                    <ion-label>{{ compensationPool.name }}</ion-label>
                    <ion-icon
                      (click)="
                        removeCompensationPool(compensationPool._id, $event)
                      "
                      name="close-circle"
                    ></ion-icon>
                  </ion-chip>
                  <ion-fab-button
                    size="small"
                    color="light"
                    class="add-button"
                    (click)="addCompensationPool()"
                    appHaptics
                  >
                    <ion-icon name="add"></ion-icon>
                  </ion-fab-button>
                </div>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-select
                  [multiple]="true"
                  label="When calculating rewards, include points from"
                  labelPlacement="floating"
                  class="app-w100 input-with-background"
                  appHaptics
                  fill="solid"
                  [value]="compensationSettings.includePointsFrom"
                  (ionChange)="onIncludePointsFromChange($any($event))"
                >
                  <ion-select-option value="tasksCompleted">
                    Tasks
                  </ion-select-option>
                  <ion-select-option value="pointsSent">
                    Recognition Sent
                  </ion-select-option>
                  <ion-select-option value="pointsReceived">
                    Recognition Received
                  </ion-select-option>
                </ion-select>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col class="app-notice">
                <strong>Table Columns for Hours from Payroll Provider</strong>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <div class="compensation-pools">
                  <ion-chip
                    class="compensation-pool"
                    *ngFor="
                      let tableField of onlyHoursCompensationFields;
                      trackBy: trackCompensationFields
                    "
                    (click)="updateCompensationField(tableField)"
                    appHaptics
                  >
                    <ion-label>{{ tableField.name }}</ion-label>
                    <ion-icon
                      (click)="removeCompensationField(tableField._id, $event)"
                      name="close-circle"
                    ></ion-icon>
                  </ion-chip>
                  <ion-fab-button
                    size="small"
                    color="light"
                    class="add-button"
                    (click)="addCompensationField()"
                    appHaptics
                  >
                    <ion-icon name="add"></ion-icon>
                  </ion-fab-button>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-input
                  label="Title of Employee ID Column"
                  class="app-w100 input-with-background"
                  labelPlacement="floating"
                  fill="solid"
                  [value]="compensationEmployeeIdColumnName"
                  appHaptics
                  [debounce]="700"
                  (ionInput)="
                    onCompensationEmployeeIdColumnNameInput($any($event))
                  "
                >
                </ion-input>
              </ion-col>
            </ion-row>
          </div>
        </ion-grid>
      </ion-accordion>

      <ion-accordion value="permissions" id="permissions">
        <ion-item slot="header" appHaptics class="team-settings-item">
          Permissions
        </ion-item>
        <ion-grid slot="content" class="team-settings-item-content">
          <ion-row>
            <ion-col class="app-notice">
              <strong>Which users can access the insights page?</strong><br />
              Only selected users will be allowed to view the insights page
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-button
                color="light"
                expand="block"
                id="selectInsightsUsers"
                appHaptics
                class="ion-no-margin"
              >
                <ng-container *ngIf="allUsers$ | async as allUsers">
                  <ng-container
                    *ngIf="allUsers | appAllowedInsights as allowList"
                  >
                    <ng-container
                      *ngIf="
                        allowList.length === allUsers.length;
                        else someUsersCaption
                      "
                    >
                      All Users
                    </ng-container>
                    <ng-template #someUsersCaption>
                      {{ allowList.length }} users selected
                    </ng-template>
                  </ng-container>
                </ng-container>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-accordion>

      <ion-accordion
        value="multiUserMode"
        id="multiUserMode"
        [attr.data-hidden]="!(tenantFeatures.multiUserMode || isMultiUserModeActive)"
      >
        <ion-item slot="header" appHaptics class="team-settings-item">
          Multi-User Mode
        </ion-item>
        <ion-grid slot="content" class="team-settings-item-content">
          <ion-row>
            <ion-col class="app-notice">
              <strong> Access the app by unique user passcode </strong>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-button
                *ngIf="isMultiUserModeActive"
                color="danger"
                expand="block"
                appHaptics
                class="ion-no-margin"
                (click)="deactivateMultiUserMode()"
              >
                Deactivate Multi-User Mode
              </ion-button>
              <ion-button
                *ngIf="!isMultiUserModeActive"
                color="primary"
                expand="block"
                appHaptics
                class="ion-no-margin"
                (click)="activateMultiUserMode()"
              >
                Activate Multi-User Mode
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-button
                id="showPasscodes"
                color="light"
                expand="block"
                appHaptics
                class="ion-no-margin"
              >
                Show Passcodes
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="!isMultiUserModeActive">
            <ion-col size="12">
              <ion-select
                labelPlacement="floating"
                fill="solid"
                aria-label="After Entering The Passcode, User Goes to"
                interface="popover"
                label="After Entering The Passcode, User Goes to"
                class="app-w100 input-with-background select-round-fix"
                [value]="passcodeRedirectsTo"
                (ionChange)="onPasscodeRedirectsToChange($any($event))"
                appHaptics
              >
                <ion-select-option value="/i/recognition">
                  Recognition
                </ion-select-option>
                <ion-select-option
                  value="/i/checklist"
                  *ngIf="tenantFeatures.checklist"
                >
                  Checklist
                </ion-select-option>
                <ion-select-option value="/i/tags" *ngIf="tenantFeatures.tags">
                  Tags
                </ion-select-option>
                <ion-select-option
                  value="/i/announcements"
                  *ngIf="tenantFeatures.announcements"
                >
                  Announcements
                </ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="!isMultiUserModeActive">
            <ion-col size="12">
              <ion-select
                labelPlacement="floating"
                fill="solid"
                aria-label="Passcode Timeout"
                interface="popover"
                label="Passcode Timeout"
                class="app-w100 input-with-background select-round-fix"
                [value]="passcodeTimeout"
                (ionChange)="onPasscodeTimeoutChange($any($event))"
                appHaptics
              >
                <ion-select-option [value]="60"> 1 minute </ion-select-option>
                <ion-select-option [value]="180"> 3 minutes </ion-select-option>
                <ion-select-option [value]="300"> 5 minutes </ion-select-option>
                <ion-select-option [value]="600">
                  10 minutes
                </ion-select-option>
                <ion-select-option [value]="900">
                  15 minutes
                </ion-select-option>
                <ion-select-option [value]="1200">
                  20 minutes
                </ion-select-option>
                <ion-select-option [value]="1800">
                  30 minutes
                </ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-accordion>

      <ion-accordion value="recognition" id="recognition">
        <ion-item slot="header" appHaptics class="team-settings-item">
          Recognition
        </ion-item>
        <ion-grid slot="content" class="team-settings-item-content">
          <ion-row>
            <ion-col size="12">
              <div class="team-settings-inner-card">
                <div class="team-settings-inner-card-text">
                  <div>
                    Encourage Managers and Administrators at next login to
                    recognize someone if they haven't in
                    <app-inline-input
                      [ngModel]="
                        recognitionSettings.encourageManagersAndAdminsNumberOfDays
                      "
                      (ngModelChange)="
                        onEncourageManagersAndAdminsNumberOfDaysChange($event)
                      "
                      [disabled]="
                        recognitionSettings.encourageManagersAndAdminsToRecognizeSomeone
                      "
                      placeholder="4"
                      appendText="day"
                      appendTextPlural="days"
                      type="number"
                    />
                  </div>
                </div>
                <ion-toggle
                  appHaptics
                  [(ngModel)]="
                    recognitionSettings.encourageManagersAndAdminsToRecognizeSomeone
                  "
                  (ionChange)="
                    onEncourageManagersAndAdminsToRecognizeSomeoneChange($event)
                  "
                  aria-label="Encourage Managers and Administrators at next login to recognize
                someone"
                />
              </div>
              <div class="team-settings-inner-card">
                <div class="team-settings-inner-card-text">
                  <app-wide-button
                    label="Also send Push Notification to"
                    [disabled]="
                      recognitionSettings.encourageManagersAndAdminsPN
                    "
                    (buttonClicked)="selectEncourageManagersAndAdmins.present()"
                  >
                    {{ recognitionSettings.encourageManagersAndAdmins.length }}
                    managers and admins selected
                  </app-wide-button>
                  <app-time-form-control
                    class="app-w100"
                    [disabled]="
                      recognitionSettings.encourageManagersAndAdminsPN
                    "
                    [(ngModel)]="
                      recognitionSettings.encourageManagersAndAdminsTime
                    "
                    (ngModelChange)="
                      onEncourageManagersAndAdminsTimeChange($event)
                    "
                    label="at"
                  />
                </div>
                <ion-toggle
                  appHaptics
                  [(ngModel)]="recognitionSettings.encourageManagersAndAdminsPN"
                  (ionChange)="onEncourageManagersAndAdminsPNChange($event)"
                  aria-label="Send Push Notification"
                />
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <div class="team-settings-inner-card">
                <div class="team-settings-inner-card-text">
                  <div>
                    Encourage normal Users at next login to recognize someone if
                    they haven't in
                    <app-inline-input
                      [ngModel]="
                        recognitionSettings.encourageNormalUsersNumberOfDays
                      "
                      (ngModelChange)="
                        onEncourageNormalUsersNumberOfDaysChange($event)
                      "
                      [disabled]="
                        recognitionSettings.encourageNormalUsersToRecognizeSomeone
                      "
                      placeholder="4"
                      appendText="day"
                      appendTextPlural="days"
                      type="number"
                    />
                  </div>
                </div>
                <ion-toggle
                  appHaptics
                  [(ngModel)]="
                    recognitionSettings.encourageNormalUsersToRecognizeSomeone
                  "
                  (ionChange)="
                    onEncourageNormalUsersToRecognizeSomeoneChange($event)
                  "
                  aria-label="Encourage normal Users at next login to recognize someone"
                />
              </div>
              <div class="team-settings-inner-card">
                <div class="team-settings-inner-card-text">
                  <app-wide-button
                    label="Also send Push Notification to"
                    [disabled]="recognitionSettings.encourageNormalUsersPN"
                    (buttonClicked)="selectNormalUsers.present()"
                  >
                    {{ recognitionSettings.encourageNormalUsers.length }}
                    normal users selected
                  </app-wide-button>
                  <app-time-form-control
                    class="app-w100"
                    [disabled]="recognitionSettings.encourageNormalUsersPN"
                    [(ngModel)]="recognitionSettings.encourageNormalUsersTime"
                    (ngModelChange)="onEncourageNormalUsersTimeChange($event)"
                    label="at"
                  />
                </div>
                <ion-toggle
                  appHaptics
                  [(ngModel)]="recognitionSettings.encourageNormalUsersPN"
                  (ionChange)="onEncourageNormalUsersPNChange($event)"
                  aria-label="Send Push Notification"
                />
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-accordion>

      <ion-accordion
        value="checklist"
        id="checklist"
        [attr.data-hidden]="!tenantFeatures.checklist"
      >
        <ion-item slot="header" appHaptics class="team-settings-item">
          Checklist
        </ion-item>
        <ion-grid slot="content" class="team-settings-item-content">
          <ion-row>
            <ion-col size="12">
              <ion-toggle
                appHaptics
                justify="space-between"
                class="app-w100"
                [(ngModel)]="
                  checklistSettings.allowTasksToBeDisplayedOnlyDuringCertainHours
                "
                (ionChange)="
                  onChecklistSettingsFieldChange(
                    'allowTasksToBeDisplayedOnlyDuringCertainHours',
                    $event
                  )
                "
              >
                <ion-label>
                  Allow individual tasks to be displayed only during certain
                  hours
                </ion-label>
              </ion-toggle>
            </ion-col>
          </ion-row>
          <ion-row [attr.data-hidden]="!tenantFeatures.timeAttack">
            <ion-col size="12">
              <ion-toggle
                appHaptics
                justify="space-between"
                class="app-w100"
                [(ngModel)]="
                  checklistSettings.requireManagerToInitiateTimeAttack
                "
                (ionChange)="
                  onChecklistSettingsFieldChange(
                    'requireManagerToInitiateTimeAttack',
                    $event
                  )
                "
              >
                <ion-label> Require manager to initiate Time Attack </ion-label>
              </ion-toggle>
            </ion-col>
          </ion-row>
          <ion-row [attr.data-hidden]="!tenantFeatures.auditTasks">
            <ion-col size="12">
              <ion-toggle
                appHaptics
                justify="space-between"
                class="app-w100"
                [(ngModel)]="checklistSettings.showLastCompleteInTasksForAudit"
                (ionChange)="
                  onChecklistSettingsFieldChange(
                    'showLastCompleteInTasksForAudit',
                    $event
                  )
                "
              >
                <ion-label>
                  Tasks for Audit: Show last completion for each task
                </ion-label>
              </ion-toggle>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-accordion>

      <ion-accordion value="tags" id="tags" [attr.data-hidden]="!tenantFeatures.tags">
        <ion-item slot="header" appHaptics class="team-settings-item">
          Tags
        </ion-item>
        <ion-grid slot="content" class="team-settings-item-content">
          <ion-row>
            <ion-col size="12">
              <ion-input
                label="Date Field Name"
                class="app-w100 input-with-background"
                type="text"
                [ngModel]="tagsSettings.pickupDateFieldName"
                [ngModelOptions]="{ standalone: true }"
                [debounce]="700"
                (ionInput)="onPickupDateFieldNameInput($any($event))"
                labelPlacement="floating"
                fill="solid"
                required
                autocapitalize="on"
                [spellcheck]="true"
              />
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-toggle
                appHaptics
                justify="space-between"
                class="app-w100"
                [(ngModel)]="tagsSettings.customerNameEnabled"
                (ionChange)="onCustomerNameEnabledChange($event)"
              >
                <ion-label> Customer Name Field </ion-label>
              </ion-toggle>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-accordion>

      <ion-accordion
        value="dailyReport"
        id="dailyReport"
        [attr.data-hidden]="!tenantFeatures.dailyReport"
      >
        <ion-item slot="header" appHaptics class="team-settings-item">
          Daily Report
        </ion-item>
        <ion-grid slot="content" class="team-settings-item-content">
          <ion-row>
            <ion-col size="12">
              <ion-toggle
                appHaptics
                justify="space-between"
                class="app-w100"
                [(ngModel)]="dailyReportEnabled"
                (ionChange)="onDailyReportEnabledChange($event)"
              >
                <ion-label> Push notification </ion-label>
              </ion-toggle>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="app-notice">
              <strong> Daily report will be sent to selected users </strong>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-button
                color="light"
                expand="block"
                id="selectDailyReportUsers"
                appHaptics
                class="ion-no-margin"
              >
                <ng-container *ngIf="allUsers$ | async as allUsers">
                  <ng-container
                    *ngIf="allUsers | appReceiveDailyReports as allowList"
                  >
                    <ng-container
                      *ngIf="
                        allowList.length === allUsers.length;
                        else someUsersCaption
                      "
                    >
                      All Users
                    </ng-container>
                    <ng-template #someUsersCaption>
                      {{ allowList.length }} users selected
                    </ng-template>
                  </ng-container>
                </ng-container>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <app-time-form-control
                class="app-w100"
                [(ngModel)]="dailyReportTime"
                label="Send report each day at"
                (ngModelChange)="onDailyReportTimeChange($event)"
              />
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-accordion>

      <ion-accordion
        value="surveyFeedback"
        id="surveyFeedback"
        [attr.data-hidden]="!tenantFeatures.surveyFeedback"
      >
        <ion-item slot="header" appHaptics class="team-settings-item">
          Feedback
        </ion-item>
        <ion-grid slot="content" class="team-settings-item-content">
          <ion-row>
            <ion-col class="app-notice">
              <strong>Which view will be presented to the users?</strong><br />
              Admins can see data in all views
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-select
                labelPlacement="floating"
                fill="solid"
                aria-label="View"
                interface="popover"
                label="View"
                class="app-w100 input-with-background select-round-fix"
                [value]="psychologicalFeedbackView"
                (ionChange)="onPsychologicalFeedbackViewChange($any($event))"
                appHaptics
              >
                <ion-select-option [value]="feedbackView.SINGLE_BAR_VIEW">
                  Single Bar View
                </ion-select-option>
                <ion-select-option [value]="feedbackView.DOUBLE_BAR_VIEW">
                  Double Bar View
                </ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-accordion>

      <ion-accordion
        value="announcements"
        id="announcements"
        *ngIf="tenantFeatures.announcements"
      >
        <ion-item slot="header" appHaptics class="team-settings-item">
          Announcements
        </ion-item>
        <ion-grid slot="content" class="team-settings-item-content">
          <ion-row>
            <ion-col size="12">
              <ion-select
                labelPlacement="floating"
                fill="solid"
                aria-label="Pay Frequency"
                interface="popover"
                label="Who can send and edit announcements?"
                class="app-w100 input-with-background select-round-fix"
                [value]="whoCanSendAnnouncements"
                (ionChange)="onWhoCanSendAnnouncementsChange($event)"
                appHaptics
              >
                <ion-select-option value="Admin+Manager">
                  Only Managers and Admins
                </ion-select-option>
                <ion-select-option value="Admin">
                  Only Admins
                </ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-accordion>

      <ion-accordion
        value="suggestions"
        id="suggestions"
        *ngIf="tenantFeatures.suggestions"
      >
        <ion-item slot="header" appHaptics class="team-settings-item">
          Suggestions
        </ion-item>
        <ion-grid slot="content" class="team-settings-item-content">
          <ion-row>
            <ion-col class="app-notice">
              <strong> Suggestion Form URL </strong>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-button
                fill="outline"
                color="primary"
                size="small"
                shape="round"
                (click)="onCopySuggestionLinkClick()"
                appHaptics
              >
                <ion-icon slot="start" name="copy-outline"></ion-icon>
                Copy Link
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-accordion>

      <ion-accordion value="pulse" id="pulse" [attr.data-hidden]="!tenantFeatures.survey">
        <ion-item slot="header" appHaptics class="team-settings-item">
          Pulse
        </ion-item>
        <ion-grid slot="content" class="team-settings-item-content">
          <ion-row>
            <ion-col class="app-notice">
              <strong>Daily Survey Mode</strong>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-segment
                [value]="dailySurveySettings.dailySurveyMode"
                (ionChange)="onDailySurveyModeChange($event)"
              >
                <ion-segment-button [value]="dailySurveyMode.REQUIRED">
                  <ion-label>On Start</ion-label>
                </ion-segment-button>
                <ion-segment-button [value]="dailySurveyMode.PUSH_NOTIFICATION">
                  <ion-label>End of Shift</ion-label>
                </ion-segment-button>
                <ion-segment-button [value]="dailySurveyMode.OFF">
                  <ion-label>Off</ion-label>
                </ion-segment-button>
              </ion-segment>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="app-notice">
              <strong
                *ngIf="
                  dailySurveySettings.dailySurveyMode ===
                  dailySurveyMode.PUSH_NOTIFICATION
                "
              >
                Prompt the users to complete the survey
                {{ currentSurveyTimeText }} prior to the end of their scheduled
                shift end time
              </strong>
              <strong
                *ngIf="
                  dailySurveySettings.dailySurveyMode ===
                  dailySurveyMode.REQUIRED
                "
              >
                Prompt users to complete the survey about their most recent
                shift the next time they open the app.
              </strong>
            </ion-col>
          </ion-row>
          <ion-row
            [attr.data-hidden]="
              dailySurveySettings.dailySurveyMode !==
              dailySurveyMode.PUSH_NOTIFICATION
            "
          >
            <ion-col size="12">
              <ion-button
                color="light"
                expand="block"
                id="selectSurveyTime"
                appHaptics
                class="ion-no-margin"
              >
                {{ currentSurveyTimeText }}
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-accordion>

      <ion-accordion value="notifications" id="notifications">
        <ion-item slot="header" appHaptics class="team-settings-item">
          Notifications
        </ion-item>
        <ion-grid slot="content" class="team-settings-item-content">
          <ion-row>
            <ion-col size="12">
              <ion-toggle
                appHaptics
                justify="space-between"
                class="app-w100"
                [(ngModel)]="
                  pnFlags.isAdminsReceiveNotificationsWhenAnyUserIsRecognized
                "
                (ionChange)="
                  onPnFlagChange(
                    $event,
                    'isAdminsReceiveNotificationsWhenAnyUserIsRecognized'
                  )
                "
              >
                <ion-label>
                  Admins receive notifications when any user is recognized
                </ion-label>
              </ion-toggle>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-toggle
                appHaptics
                justify="space-between"
                class="app-w100"
                [(ngModel)]="
                  pnFlags.isManagersReceiveNotificationsWhenAnyUserIsRecognized
                "
                (ionChange)="
                  onPnFlagChange(
                    $event,
                    'isManagersReceiveNotificationsWhenAnyUserIsRecognized'
                  )
                "
              >
                <ion-label>
                  Managers receive notifications when any user is recognized
                </ion-label>
              </ion-toggle>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-toggle
                appHaptics
                justify="space-between"
                class="app-w100"
                [(ngModel)]="
                  pnFlags.isUsersReceiveNotificationsForAnnouncements
                "
                (ionChange)="
                  onPnFlagChange(
                    $event,
                    'isUsersReceiveNotificationsForAnnouncements'
                  )
                "
              >
                <ion-label>
                  Users receive notifications for announcements
                </ion-label>
              </ion-toggle>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-accordion>

      <ion-accordion value="culture" id="culture">
        <ion-item slot="header" appHaptics class="team-settings-item">
          Culture
        </ion-item>
        <ion-grid slot="content" class="team-settings-item-content">
          <ion-row>
            <ion-col size="12">
              <ion-button
                color="light"
                (click)="manageBehaviors()"
                expand="block"
                appHaptics
                class="ion-no-margin"
              >
                Manage behaviors
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-accordion>
    </ion-accordion-group>
  </app-width-restricted-container>
</ion-content>

<ion-modal
  trigger="selectInsightsUsers"
  #selectInsightsUsersModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <ng-container *ngIf="allUsers$ | async as allUsers">
      <app-user-selector
        class="ion-page"
        [users]="allUsers"
        [recipients]="allUsers | appAllowedInsights"
        (selectionChange)="insightsUsersChanged($event, allUsers)"
        (selectionCancel)="selectInsightsUsersModal.dismiss()"
        [multiple]="true"
      ></app-user-selector>
    </ng-container>
  </ng-template>
</ion-modal>

<ion-modal
  trigger="selectDailyReportUsers"
  #selectDailyReportUsers
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <ng-container *ngIf="allUsers$ | async as allUsers">
      <app-user-selector
        class="ion-page"
        [users]="allUsers"
        [recipients]="allUsers | appReceiveDailyReports"
        (selectionChange)="dailyReportUsersChanged($event, allUsers)"
        (selectionCancel)="selectDailyReportUsers.dismiss()"
        [multiple]="true"
      ></app-user-selector>
    </ng-container>
  </ng-template>
</ion-modal>

<ion-modal
  #selectEncourageManagersAndAdmins
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <ng-container *ngIf="allUsers$ | async as allUsers">
      <app-user-selector
        class="ion-page"
        [users]="allUsers | appManagersAndAdmins"
        [recipients]="encourageManagersAndAdmins"
        (selectionChange)="encourageManagersAndAdminsChanged($event)"
        (selectionCancel)="selectEncourageManagersAndAdmins.dismiss()"
        [multiple]="true"
      ></app-user-selector>
    </ng-container>
  </ng-template>
</ion-modal>

<ion-modal
  #selectNormalUsers
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <ng-container *ngIf="allUsers$ | async as allUsers">
      <app-user-selector
        class="ion-page"
        [users]="allUsers | appNormalUsers"
        [recipients]="encourageNormalUsers"
        (selectionChange)="encourageNormalUsersChanged($event)"
        (selectionCancel)="selectNormalUsers.dismiss()"
        [multiple]="true"
      ></app-user-selector>
    </ng-container>
  </ng-template>
</ion-modal>

<ion-modal
  trigger="selectTimezone"
  #timezoneSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-timezone-selector
      class="ion-page"
      [timezones]="timezones"
      [initialTzCode]="timezone"
      (selectionChange)="onTimezoneSelect($event)"
      (selectionCancel)="timezoneSelectorModal.dismiss()"
    >
    </app-timezone-selector>
  </ng-template>
</ion-modal>

<ion-modal
  trigger="showPasscodes"
  #passcodesListModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-passcodes-list-modal
      class="ion-page"
      [passcodeRedirectsTo]="passcodeRedirectsTo"
      [passcodeTimeout]="passcodeTimeout"
    />
  </ng-template>
</ion-modal>

<ion-picker
  trigger="selectSurveyTime"
  [columns]="surveyTimePickerColumns"
  [buttons]="surveyTimePickerButtons"
  (willDismiss)="onDailySurveyPNTimeChange($event)"
></ion-picker>

<ion-loading #loading message="Loading..."></ion-loading>
