<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
    <ion-title>Feedback</ion-title>
    <ion-buttons slot="end" [attr.data-hidden]="!isAdmin">
      <ion-button id="popover-button" appHaptics>
        <ion-icon slot="icon-only" name="options-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar">
    <ion-segment [value]="currentTab" (ionChange)="onCurrentTabChange($event)">
      <ion-segment-button [value]="feedbackType.TEAM">
        <ion-label>Team</ion-label>
      </ion-segment-button>
      <ion-segment-button [value]="feedbackType.PERSONAL">
        <ion-label>Personal</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>

  <ion-toolbar
    class="app-toolbar user-selector-toolbar"
    [ngClass]="{
      'user-selector-toolbar--show':
        isAdmin && currentTab === feedbackType.PERSONAL
    }"
  >
    <ion-segment value="0">
      <ion-segment-button value="0" (click)="userSelectorModal.present()">
        <ion-label>{{ formattedUser }}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <ng-container *ngIf="surveyBehaviors.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>

    <div
      class="ion-padding-horizontal"
      *ngIf="!isLoading && surveyBehaviors.length > 0"
    >
      <div
        *ngIf="
          selectedView === feedbackView.DOUBLE_BAR_VIEW;
          else singleBarView
        "
      >
        <app-feedback-double-bar
          *ngFor="
            let feedback of surveyBehaviors;
            let i = index;
            trackBy: trackFeedbacks
          "
          [name]="feedback.pastTense"
          [positiveValue]="
            currentTab === feedbackType.PERSONAL
              ? feedback.personalFeedback.positiveCount
              : feedback.teamFeedback.positiveCount
          "
          [negativeValue]="
            currentTab === feedbackType.PERSONAL
              ? feedback.personalFeedback.negativeCount
              : feedback.teamFeedback.negativeCount
          "
          [maxValue]="
            currentTab === feedbackType.PERSONAL
              ? maxPersonalValue
              : maxTeamValue
          "
          [animationOrder]="i"
        />
      </div>
    </div>
  </app-width-restricted-container>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub
    text="You have not been given any feedback yet"
  ></app-empty-stub>
</ng-template>

<ion-modal
  #userSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      [focusSearchbar]="true"
      title=""
      class="ion-page"
      [users]="allUsers"
      [recipients]="selectedUserIds"
      (selectionChange)="onUserChange($event)"
      (selectionCancel)="userSelectorModal.dismiss()"
    ></app-user-selector>
  </ng-template>
</ion-modal>

<ng-template #singleBarView>
  <div>
    <app-feedback-single-bar
      *ngFor="
        let feedback of surveyBehaviors;
        let i = index;
        trackBy: trackFeedbacks
      "
      [name]="feedback.pastTense"
      [value]="
        currentTab === feedbackType.PERSONAL
          ? feedback.personalFeedback.finalValue - minPersonalFinalValue
          : feedback.teamFeedback.finalValue - minTeamFinalValue
      "
      [maxValue]="
        currentTab === feedbackType.PERSONAL
          ? maxPersonalFinalValue - minPersonalFinalValue
          : maxTeamFinalValue - minTeamFinalValue
      "
      [animationOrder]="i"
    />
  </div>
</ng-template>

<ion-popover trigger="popover-button">
  <ng-template>
    <ion-list lines="none">
      <ion-radio-group
        [value]="selectedView"
        (ionChange)="handleViewChange($event)"
      >
        <ion-item>
          <ion-radio [value]="feedbackView.SINGLE_BAR_VIEW">
            Single Bar View
          </ion-radio>
        </ion-item>
        <ion-item>
          <ion-radio [value]="feedbackView.DOUBLE_BAR_VIEW">
            Double Bar View
          </ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
  </ng-template>
</ion-popover>
