<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
    <ion-title>Account Settings</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container
    class="layout-container"
    [formGroup]="mainFormGroup"
  >
    <div class="top-content">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-input
              label="First Name"
              class="app-w100 input-with-background"
              labelPlacement="floating"
              fill="solid"
              formControlName="firstName"
              autocapitalize="on"
            >
            </ion-input>
            <ion-note color="danger" *ngIf="firstNameError" class="error-note">
              {{ firstNameError }}
            </ion-note>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-input
              label="Last Name"
              class="app-w100 input-with-background"
              labelPlacement="floating"
              fill="solid"
              formControlName="lastName"
              autocapitalize="on"
            >
            </ion-input>
            <ion-note color="danger" *ngIf="lastNameError" class="error-note">
              {{ lastNameError }}
            </ion-note>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-item class="app-w100 input-with-background" lines="none">
              <ion-input
                label="Email"
                labelPlacement="floating"
                fill="solid"
                formControlName="email"
                [readonly]="true"
              ></ion-input>
              <ion-button
                *ngIf="!user.parent"
                fill="clear"
                size="default"
                appHaptics
                class="ion-no-margin"
                (click)="requestEmailChange()"
              >
                Change
              </ion-button>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="!user.parent">
          <ion-col size="12">
            <ion-item class="app-w100 input-with-background" lines="none">
              <ion-input
                label="Password"
                labelPlacement="floating"
                fill="solid"
                [readonly]="true"
                value="******"
              ></ion-input>
              <ion-button
                *ngIf="!isResetPasswordEmailSent"
                fill="clear"
                size="default"
                appHaptics
                class="ion-no-margin"
                (click)="requestResetPassword()"
              >
                Reset
              </ion-button>
            </ion-item>
          </ion-col>
        </ion-row>
        <app-user-schedule-editor
          *ngIf="tenantFeatures.userSchedule"
          [scheduleFormGroup]="scheduleFormGroup"
          [weekTimeFormGroup]="weekTimeFormGroup"
        />
      </ion-grid>
    </div>

    <div class="bottom-content">
      <ion-grid>
        <!-- <ion-row class="ion-justify-content-center">
          <ion-col>
            <div class="ion-text-center">
              <div class="pns-label">Notifications</div>
              <ion-toggle
                class="ion-no-padding"
                formControlName="receivePNs"
                appHaptics
              >
              </ion-toggle>
              <div class="pns-description">
                Receive push notifications on mobile device
              </div>
            </div>
          </ion-col>
        </ion-row> -->
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="6">
            <ion-button
              color="dark"
              (click)="submit()"
              appHaptics
              expand="block"
              [disabled]="!canSave"
              [disableHaptics]="!canSave"
            >
              Save Changes
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>
