import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, LocalStorageService, ROLES } from '@app/core';
import { Observable } from 'rxjs';
import { UserModel, UserPointsRecord } from '@app/core/model/user.model';
import { UserEntityService } from '@app/core/service/user-entity.service';
import { AnnouncementListService } from '@app/core/service/announcement-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalSettings } from '@app/types/global-settings';
import { EnvironmentService } from '@app/core/service/environment.service';
import { IonMenu, LoadingController } from '@ionic/angular';
import { TenantService } from '@app/core/service/tenant.service';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { App } from '@capacitor/app';
import {
  animate,
  style,
  transition,
  trigger,
  state
} from '@angular/animations';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { ApprovalsListService } from '@app/core/service/approvals-list.service';
import { ITask } from '@backend/models/types/task';
import { IConnectedAccount } from '@app/core/service/connected-accounts-api.service';
import { ConnectedAccountsListService } from '@app/core/service/connected-accounts-list.service';
import { InstantFeedbackService } from '@app/core/service/instant-feedback.service';
import { EDailySurveyMode } from '@backend/types/daily-survey-definition';

export enum ESubmenu {
  INSIGHTS = 'INSIGHTS',
  DEVELOPER = 'DEVELOPER',
  SETTINGS = 'SETTINGS'
}

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
  animations: [
    trigger('submenuInOut', [
      state(
        'in',
        style({
          height: '*'
        })
      ),
      state(
        'out',
        style({
          height: '0px'
        })
      ),
      transition('in => out', animate('250ms cubic-bezier(0.22, 1, 0.36, 1)')),
      transition('out => in', animate('250ms cubic-bezier(0.22, 1, 0.36, 1)'))
    ])
  ]
})
export class FullComponent implements OnInit {
  @ViewChild('mobileMenu', { static: true })
  public mobileMenu!: IonMenu;

  protected readonly openedSubmenuType = ESubmenu;
  protected currentUser$: Observable<UserModel> = this._userEntityService.user$;
  protected isAdmin: boolean;
  protected isSuperAdmin: boolean;
  // protected receivedInPeriod$: Observable<UserPointsRecord> =
  //   this._userEntityService.receivedInPeriod$;
  // protected teamAverageInPeriod$: Observable<{ teamAveragePoints: number }> =
  //   this._userEntityService.teamAverageInPeriod$;
  // protected hideSubscription = false;
  protected unreadAnnouncementsCount$: Observable<number>;
  protected unreadInstantFeedbacksCount$: Observable<number>;
  protected tenantFeatures: Pick<
    ITenantFeatures,
    | 'checklist'
    | 'tags'
    | 'insights'
    | 'dailyReport'
    | 'surveyFeedback'
    | 'announcements'
    | 'survey'
    | 'connectedAccounts'
    | 'library'
    | 'suggestions'
  > = {
    checklist: false,
    tags: false,
    announcements: false,
    survey: false,
    surveyFeedback: false,
    insights: false,
    dailyReport: false,
    connectedAccounts: false,
    library: false,
    suggestions: false
  };
  protected showPulse = false;
  protected tenantName = '';
  protected openedMenu: ESubmenu = null;
  protected closedMenu: ESubmenu = null;
  protected waitingForApprovalsTaskPerformances: MongoStoredObject<
    ITaskPerformance & {
      user: UserModel;
      task: MongoStoredObject<ITask>;
    }
  >[] = [];
  protected showUserSelector = false;
  protected myConnectedAccounts: IConnectedAccount[] = [];

  public constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _userEntityService: UserEntityService,
    private readonly _announcementListService: AnnouncementListService,
    private readonly _tenantService: TenantService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _environmentService: EnvironmentService,
    private readonly _router: Router,
    private readonly _approvalsListService: ApprovalsListService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _connectedAccountsListService: ConnectedAccountsListService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _instantFeedbackService: InstantFeedbackService
  ) {
    this._authenticationService.lastPushNotification$.subscribe(() => {
      this.mobileMenu.close();
    });

    this._connectedAccountsListService.myConnectedAccounts$.subscribe(
      (myConnectedAccounts) => {
        this.myConnectedAccounts = myConnectedAccounts
          .sort((x, y) => {
            return !!x.parent === !!y.parent ? 0 : x.parent ? 1 : -1;
          })
          .sort((a, b) => {
            if (a.email < b.email) {
              return -1;
            }
            if (a.email > b.email) {
              return 1;
            }
            return 0;
          });
      }
    );

    this.isAdmin =
      this._authenticationService.user?.roles.includes(ROLES.adminName) ??
      false;
    this.unreadAnnouncementsCount$ =
      this._announcementListService.unacknowledgedCount$;
    const globalSettings: GlobalSettings =
      this._activatedRoute.snapshot.data.globalSettings;
    this.isSuperAdmin = !!globalSettings.superAdmins.find(
      (user) =>
        user._id.toString() === this._authenticationService.user._id.toString()
    );

    this._tenantService.tenant$.subscribe((tenant) => {
      this.tenantFeatures = tenant.features;
      this.tenantName = tenant.name;
      this.showPulse =
        tenant.features.survey &&
        tenant.settings.dailySurvey.dailySurveyMode ===
          EDailySurveyMode.PUSH_NOTIFICATION;
    });

    if (
      this._authenticationService.user.roles.includes(ROLES.adminName) ||
      this._authenticationService.user.roles.includes(ROLES.managerName)
    ) {
      this._approvalsListService.approvals$.subscribe(
        (waitingForApprovalsTaskPerformances) => {
          this.waitingForApprovalsTaskPerformances =
            waitingForApprovalsTaskPerformances;
        }
      );
    }

    if (this.isSuperAdmin) {
      this.unreadInstantFeedbacksCount$ =
        this._instantFeedbackService.unreadCount$;
    }

    App.addListener('appUrlOpen', () => {
      this.mobileMenu.close();
    });
  }

  public ngOnInit() {
    // this.hideSubscription = Capacitor.getPlatform() !== 'web';

    this._userEntityService.update();
  }

  protected get isMultiUserModeActive(): boolean {
    return !!this._localStorageService.getItem('multiUserMode');
  }

  protected get currentRoute() {
    return this._router.url;
  }

  protected get appVersion() {
    return this._environmentService.version;
  }

  protected addAccount() {
    this._authenticationService.logout({ addConnectedAccount: true });
  }

  protected logoutUser(event: any) {
    event.preventDefault();

    this._authenticationService.logout();
  }

  protected toggleSubmenu(submenu: ESubmenu) {
    if (!this.closedMenu) {
      if (this.openedMenu) {
        this.closedMenu = null;
      }

      if (this.openedMenu === submenu) {
        this.openedMenu = null;
      } else {
        this.openedMenu = submenu;
      }
    }
  }

  protected changeAccount(userId: string) {
    this._authenticationService.logout({
      loginToConnectedAccount: userId,
      navigateTo: 'i/recognition'
    });
  }

  protected async removeAccount(userId: string, event: any) {
    event.stopPropagation();

    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._connectedAccountsListService
      .removeMyConnectedAccount(userId)
      .subscribe(() => {
        loading.dismiss();
      });
  }
}
