import { Component, ViewChild } from '@angular/core';
import { AuthenticationService, ROLES } from '@app/core';
import { SuggestionApiService } from '@app/core/service/suggestion-api.service';
import {
  IonNav,
  LoadingController,
  ViewWillEnter,
  IonInfiniteScroll
} from '@ionic/angular';
import { SuggestionAddComponent } from './components/suggestion-add/suggestion-add.component';
import { OpportunityModel } from '@app/core/model/suggestion.model';
import { SuggestionViewComponent } from './components/suggestion-view/suggestion-view.component';
import { ESuggestionStatus } from '@backend/models/types/suggestion';
import { PullToSearchComponent } from '@app/modules/pull-to-search/pull-to-search.component';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent implements ViewWillEnter {
  @ViewChild('infiniteScroll', { static: true })
  public infiniteScroll!: IonInfiniteScroll;

  @ViewChild('pullToSearch', { static: true })
  public pullToSearch!: PullToSearchComponent;

  protected isAdmin = false;
  protected opportunities: OpportunityModel[] = [];
  protected isLoading = true;
  protected hasNextPage = true;
  protected searchTerm = '';
  protected page = 1;
  protected pageSize = 20;

  public constructor(
    private readonly _suggestionApiService: SuggestionApiService,
    private readonly _ionNav: IonNav,
    private readonly _authenticationService: AuthenticationService,
    private readonly _loadingCtrl: LoadingController
  ) {
    this.isAdmin = this._authenticationService.user.roles.includes(
      ROLES.adminName
    );
  }

  public ionViewWillEnter(): void {
    this._fetchOpportunities(true);
  }

  public async ionViewDidEnter() {
    const topLoading = await this._loadingCtrl.getTop();
    if (topLoading) {
      topLoading.dismiss();
    }
  }

  protected _fetchOpportunities(reset = false) {
    if (reset) {
      this.isLoading = true;
      this.page = 1;
      this.opportunities = [];
    }

    (this.isAdmin
      ? this._suggestionApiService.getAllOpportunities(
          this.page,
          this.pageSize,
          this.searchTerm
        )
      : this._suggestionApiService.getPublicOpportunities(
          this.page,
          this.pageSize,
          this.searchTerm
        )
    ).subscribe((fetchedDocs) => {
      this.hasNextPage = fetchedDocs.length === this.pageSize;
      this.opportunities = this.opportunities.concat(fetchedDocs);
      this.isLoading = false;
      this.pullToSearch.initSearch();
      this.infiniteScroll.complete();
    });
  }

  protected onAddClick(): void {
    this._ionNav.push(SuggestionAddComponent);
  }

  protected viewDoc(suggestion: OpportunityModel) {
    this._ionNav.push(SuggestionViewComponent, { suggestion });
  }

  protected trackItems(_: number, item: OpportunityModel) {
    return item._id.toString();
  }

  protected getStatusColor(status: ESuggestionStatus) {
    switch (status) {
      case ESuggestionStatus.AWAITING_REVIEW:
        return 'primary';
      case ESuggestionStatus.IN_QUEUE:
        return 'tertiary';
      case ESuggestionStatus.IN_PROGRESS:
        return 'warning';
      case ESuggestionStatus.RESOLVED:
        return 'success';
    }
  }

  protected getSolutionsText(opp: OpportunityModel) {
    if (opp.solutions.length === 1) {
      return '1 solution';
    }
    return `${opp.solutions.length} solutions`;
  }

  protected getAuthorName(opp: OpportunityModel) {
    return (
      (opp.author
        ? `${opp.author.firstName} ${opp.author.lastName}`
        : opp.authorName) || 'Anonymous'
    );
  }

  protected onIonInfinite() {
    this.page = this.page + 1;
    this._fetchOpportunities();
  }

  protected handleSearchInput(value: string) {
    this.searchTerm = value;
    this._fetchOpportunities(true);
  }
}
