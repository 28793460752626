<app-login-page-wrapper [backBtn]="true">
  <form (ngSubmit)="onFormSubmit()" novalidate>
    <ion-row>
      <ion-col size="12">
        <h1>Share Feedback</h1>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <div
          class="autocomplete-holder"
          (clickOutside)="userSelectIsOpen = false"
        >
          <ion-input
            appAutofill
            type="text"
            class="login-form-control"
            [(ngModel)]="userSearchTerm"
            placeholder="Name (optional)"
            name="author"
            (ionInput)="userSelectIsOpen = true"
            (ionFocus)="userSelectIsOpen = true"
            [disabled]="selectedUser"
          />
          <ion-button
            *ngIf="selectedUser"
            (click)="onClearUserClick()"
            class="ion-no-margin"
            fill="clear"
          >
            <ion-icon slot="icon-only" name="close-circle-outline" />
          </ion-button>
          <ng-container *ngIf="allUsers | filterUsers : userSearchTerm as list">
            <div
              *ngIf="userSelectIsOpen && list.length"
              class="autocomplete-popover"
            >
              <ion-list lines="none">
                <ion-item
                  [button]="true"
                  [detail]="false"
                  *ngFor="let user of list"
                  (click)="onUserSelect(user)"
                >
                  <ion-label>
                    {{ user.firstName }} {{ user.lastName }}
                  </ion-label>
                </ion-item>
              </ion-list>
            </div>
          </ng-container>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <app-wysiwyg-editor
          [(value)]="body"
          [disableAttachments]="disableAttachments"
          [dark]="true"
          toolbarMode="image-only"
        />
      </ion-col>
    </ion-row>
    <div class="controls">
      <ion-button
        type="submit"
        color="light"
        fill="clear"
        [disabled]="!canSave"
        appHaptics
        [disableHaptics]="!canSave"
      >
        Submit
      </ion-button>

      <!-- this input is the hack to force submit event to fire on the enter press -->
      <input type="submit" class="hidden-control" />
    </div>
  </form>
</app-login-page-wrapper>
