import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { ToastService } from '@app/core/service/toast.service';
import { LoadingController } from '@ionic/angular';
import { finalize } from 'rxjs';
import { confirmPasswordValidator } from '@app/utils/form-validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../login/login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent {
  protected userId: string;
  protected token: string;
  protected email: string;

  protected mainFormGroup: FormGroup = new FormGroup(
    {
      password: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(6)
      ]),
      confirmPassword: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(6)
      ])
    },
    {
      validators: [confirmPasswordValidator]
    }
  );

  public constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _authenticationService: AuthenticationService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _toastService: ToastService,
    private readonly _loadingCtrl: LoadingController
  ) {
    this._route.queryParams.subscribe((params) => {
      if (params['user'] && params['token'] && params['email']) {
        this.userId = params['user'];
        this.token = params['token'];
        this.email = params['email'];
      } else {
        this._router.navigate(['/login'], { replaceUrl: true });
      }
    });
  }

  protected async submit() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    const updatedUser = {
      password: this.mainFormGroup.value.password,
      token: this.token,
      email: this.email
    };

    this._authenticationService
      .setNewPassword(this.userId, updatedUser)
      .pipe(
        finalize(() => {
          loading.dismiss();
          this.mainFormGroup.markAsPristine();
          this._cdr.markForCheck();
        })
      )
      .subscribe({
        next: () => {
          loading.dismiss();
          this._toastService.presentToast('Password has been changed.');
          this._router.navigate(['/login']);
        },
        error: ({ error }) => {
          loading.dismiss();
          this._toastService.presentToast(
            error.message || 'Something went wrong'
          );
          this._cdr.markForCheck();
        }
      });
  }

  protected goToLogin() {
    this._router.navigate(['/login']);
  }
}
