import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormControl, Validators, FormGroup } from '@angular/forms';

export interface ISystemEntity {
  name: string;
  points: number;
}

@Component({
  selector: 'app-edit-system-entity-modal',
  templateUrl: './edit-system-entity-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSystemEntityModalComponent implements OnInit {
  @Input()
  public readonly entity: ISystemEntity;

  protected form = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    points: new FormControl<string>('', Validators.required)
  });

  public constructor(private readonly _modalCtrl: ModalController) {}

  public ngOnInit(): void {
    this.form.reset({
      points: this.entity.points.toString(),
      name: this.entity.name
    });
  }

  protected get canSave() {
    return (
      this.form.value.name &&
      (this.form.value.name !== this.entity.name ||
        (this.form.value.points || '0') !== this.entity.points.toString())
    );
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', 'edit-system-entity-modal');
  }

  protected onSaveClick(): void {
    const result: ISystemEntity = {
      points: Number(this.form.value.points) || 0,
      name: this.form.value.name
    };

    this._modalCtrl.dismiss(result, 'confirm', 'edit-system-entity-modal');
  }
}
