<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title>
      {{ createMode ? 'Send Recognition' : 'Edit Recognition' }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="isMobile; else desktop">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ion-content>

<ng-template #desktop>
  <div>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #content>
  <app-width-restricted-container class="layout-container" [formGroup]="form">
    <div class="top-content">
      <ion-grid>
        <ion-row>
          <ion-col size="6">
            <app-wide-button
              (buttonClicked)="presentSubModal(userSelectorModal)"
              label="Recipient"
            >
              {{
                currentUser
                  ? currentUser.firstName + ' ' + currentUser.lastName
                  : 'Select User'
              }}
            </app-wide-button>
          </ion-col>
          <ion-col size="6">
            <app-wide-button
              (buttonClicked)="presentSubModal(behaviorSelectorModal)"
              label="Value"
            >
              {{ currentBehavior ? currentBehavior.name : 'Select Value' }}
            </app-wide-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-textarea
              label="Comment"
              class="app-w100 input-with-background fix-textarea"
              formControlName="description"
              labelPlacement="floating"
              fill="solid"
              [rows]="10"
              autocapitalize="on"
            ></ion-textarea>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="bottom-content">
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col>
            <div class="ion-text-center">
              <div class="private-info-label">Private</div>
              <ion-toggle
                class="ion-no-padding"
                formControlName="isPrivate"
                appHaptics
                aria-label="Private"
              />
              <div class="private-info-description">
                {{ privateInfo.description }}
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="6">
            <ion-button
              type="submit"
              color="dark"
              expand="block"
              [disabled]="!canSave"
              (click)="onSaveClick()"
              appHaptics
              [disableHaptics]="!canSave"
            >
              Submit
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ng-template>

<ion-modal
  #userSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
  [presentingElement]="presentingElement"
>
  <ng-template>
    <app-user-selector
      [focusSearchbar]="true"
      title="Recipient"
      class="ion-page"
      [users]="allUsers"
      [recipients]="selectedUserIds"
      (selectionChange)="onUserChange($event)"
      (selectionCancel)="userSelectorModal.dismiss()"
    ></app-user-selector>
  </ng-template>
</ion-modal>

<ion-modal
  id="behavior-selector-modal"
  #behaviorSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
  [presentingElement]="presentingElement"
>
  <ng-template>
    <app-behavior-selector
      [focusSearchbar]="true"
      class="ion-page"
      [behaviors]="behaviors"
      (selectionChange)="onBehaviorChange($event)"
      (selectionCancel)="behaviorSelectorModal.dismiss()"
      presentingElementSelector="#behavior-selector-modal"
    ></app-behavior-selector>
  </ng-template>
</ion-modal>
