import { Pipe, PipeTransform } from '@angular/core';
import FuzzySearch from 'fuzzy-search';
import { UserNameModel } from '@app/core/model/user.model';

@Pipe({
  name: 'filterUsers',
  standalone: true
})
export class FilterUsersPipe implements PipeTransform {
  public transform(items: UserNameModel[], filter: string): UserNameModel[] {
    if (filter.length === 0) {
      return items;
    }
    const searcher = new FuzzySearch(items, ['firstName', 'lastName'], {
      caseSensitive: false
    });
    return searcher.search(filter);
  }
}
