import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ObjectId } from '../../types/object-id';
import { Observable } from 'rxjs';
import { MongoStoredObject } from '../../types/mongo-stored-object';
import {
  ITenant,
  ITenantPushNotificationsFlags
} from '@backend/models/types/tenant';
import { PayrollDefinition } from '@backend/types/payroll-definition';
import { TaskDto } from '@app/types/task-dto';
import { ITask } from '@backend/models/types/task';
import { UserModel } from '../model/user.model';
import { IIntegration } from '@backend/models/types/integration';
import { ITaskFolder } from '@backend/models/types/task-folder';
import { CompensationDefinition } from '@backend/types/compensation-definition';
import { ChecklistDefinition } from '@backend/types/checklist-definition';
import { DailySurveyDefinition } from '@backend/types/daily-survey-definition';
import { RecognitionDefinition } from '@backend/types/recognition-definition';
import { TagsDefinition } from '@backend/types/tags-definition';
import { BehaviorModel } from '../model/behavior.model';
import { AnnouncementsDefinition } from '@backend/types/announcements-definition';

export interface PatchInput {
  name?: string;
  settings?: {
    payroll?: PayrollDefinition;
    compensation?: CompensationDefinition;
    checklist?: ChecklistDefinition;
    dailySurvey?: DailySurveyDefinition;
    recognition?: Omit<
      RecognitionDefinition,
      'encourageManagersAndAdmins' | 'encourageNormalUsers'
    > & {
      encourageManagersAndAdmins: string[];
      encourageNormalUsers: string[];
    };
    tags?: TagsDefinition;
    announcements?: AnnouncementsDefinition;
  };
  timezone?: string;
  loginRedirectsTo?: string;
  passcodeRedirectsTo?: string;
  passcodeTimeout?: number;
  dailyReportEnabled?: boolean;
  dailyReportTime?: string;
  pnFlags?: ITenantPushNotificationsFlags;
  psychologicalFeedbackView?: string;
}

export interface PatchUsersInput {
  isInsightsHidden?: boolean;
  receiveDailyReports?: boolean;
}

type PatchUserInput = Partial<Pick<UserModel, 'email'>>;

export interface CreateTenantInput {
  tenantName: string;
  adminEmail: string;
  timezone: string;
}

export type TenantPatchInput = Partial<
  Pick<ITenant, 'name' | 'isDeleted' | 'features'>
>;

@Injectable({ providedIn: 'root' })
export class TenantApiService {
  public constructor(private _httpClient: HttpClient) {}

  public getById(id: ObjectId): Observable<MongoStoredObject<ITenant>> {
    return this._httpClient.get<MongoStoredObject<ITenant>>(`/tenants/${id}`);
  }

  public setBehaviorPurpose(
    tenantId: ObjectId,
    purpose: keyof ITenant['behaviors'],
    behaviorId: ObjectId | null
  ) {
    return this._httpClient.put(`/tenants/${tenantId}/behaviors/${purpose}`, {
      behaviorId
    });
  }

  public patch(data: PatchInput): Observable<unknown> {
    return this._httpClient.patch(`/tenants`, data);
  }

  public patchUsers(
    tenantId: ObjectId,
    userIds: ObjectId[],
    query: PatchUsersInput
  ): Observable<unknown> {
    return this._httpClient.patch(`/tenants/${tenantId}/users`, query, {
      params: { userId: userIds }
    });
  }

  public patchUser(
    tenantId: ObjectId,
    userId: ObjectId,
    data: PatchUserInput
  ): Observable<unknown> {
    return this._httpClient.patch(`/tenants/${tenantId}/users/${userId}`, data);
  }

  public getAllTenants(): Observable<
    MongoStoredObject<ITenant & { integrations: IIntegration }>[]
  > {
    return this._httpClient.get<
      MongoStoredObject<ITenant & { integrations: IIntegration }>[]
    >(`/tenants`);
  }

  public createTenant(data: CreateTenantInput): Observable<unknown> {
    return this._httpClient.post('/tenants', data);
  }

  public patchTenantById(
    id: ObjectId,
    data: TenantPatchInput
  ): Observable<MongoStoredObject<ITenant>> {
    return this._httpClient.patch<MongoStoredObject<ITenant>>(
      `/tenants/${id}`,
      data
    );
  }

  public getTenantTasks(id: ObjectId) {
    return this._httpClient.get<
      MongoStoredObject<
        TaskDto & { taskFolder: MongoStoredObject<ITaskFolder> }
      >[]
    >(`/tenants/${id}/tasks`);
  }

  public postCopyTasksToTenant(
    tenantId: ObjectId,
    tasks: ObjectId[],
    skipSameNameTasks: boolean
  ): Observable<MongoStoredObject<ITask>[]> {
    return this._httpClient.post<MongoStoredObject<ITask>[]>(
      `/tenants/${tenantId}/tasks/copy-from`,
      { tasks, skipSameNameTasks }
    );
  }

  public getSysGenBehaviorsAndTasks(): Observable<{
    behaviors: BehaviorModel[];
    tasks: MongoStoredObject<TaskDto>[];
  }> {
    return this._httpClient.get<{
      behaviors: BehaviorModel[];
      tasks: MongoStoredObject<TaskDto>[];
    }>('/tenants/sys-gen-behaviors-and-tasks');
  }
}
