import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ToastService } from '@app/core/service/toast.service';
import { LoadingController } from '@ionic/angular';
import { TenantService } from '@app/core/service/tenant.service';
import { UploadFileApiService } from '@app/core/service/upload-file-api.service';
import { SuggestionApiService } from '@app/core/service/suggestion-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { UserNameModel } from '@app/core/model/user.model';
import { UserApiService } from '@app/core';

@Component({
  selector: 'app-suggestion-form',
  templateUrl: './suggestion-form.component.html',
  styleUrls: ['../login/login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestionFormComponent {
  protected body = '';
  protected disableAttachments = false;
  protected tenantId = '';
  protected allUsers: UserNameModel[] = [];
  protected selectedUser: string = null;
  protected userSelectIsOpen = false;
  protected userSearchTerm = '';

  public constructor(
    private readonly _suggestionApiService: SuggestionApiService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService,
    private readonly _tenantService: TenantService,
    private readonly _uploadFileApiService: UploadFileApiService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _userApiService: UserApiService
  ) {
    this._tenantService.tenant$.pipe(take(1)).subscribe((tenant) => {
      this.disableAttachments = !tenant.features.suggestionAttachments;
    });

    this._route.params.subscribe((params) => {
      if (params.tenant && params.tenant.length) {
        this.tenantId = params.tenant;

        this._userApiService.getAllUsersNamesOfTenant(this.tenantId).subscribe({
          next: (users) => {
            this.allUsers = users;
          },
          error: () => {
            this._toastService.presentToast('Wrong tenant provided');
            this._router.navigate(['/']);
          }
        });
      } else {
        this._router.navigate(['/']);
      }
    });
  }

  protected async onFormSubmit() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._uploadFileApiService
      .extractAttachmentsFromBody(this.body || '', [])
      .subscribe({
        next: ({ body, newAttachments, keptAttachments }) => {
          this._suggestionApiService
            .postOpportunity({
              body,
              attachments: [
                ...keptAttachments,
                ...newAttachments.map((a) => a._id.toString())
              ],
              tenant: this.tenantId,
              author: this.selectedUser || undefined,
              authorName: this.userSearchTerm || undefined
            })
            .subscribe(() => {
              loading.dismiss();

              this._toastService.presentToast(
                'Suggestion Successfully Submitted!'
              );

              this._router.navigate(['/']);
            });
        },
        error: (e) => {
          console.log(e);
          loading.dismiss();
          this._toastService.presentToast('Unable to upload attachments');
        }
      });
  }

  protected get canSave() {
    return this.body;
  }

  protected onUserSelect(user: UserNameModel) {
    this.userSearchTerm = `${user.firstName} ${user.lastName}`;
    this.userSelectIsOpen = false;
    this.selectedUser = user._id;
  }

  protected onClearUserClick() {
    this.selectedUser = null;
    this.userSearchTerm = '';
  }
}
