import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormErrorWrapperModule } from '../../modules/form-error-wrapper/form-error-wrapper.module';
import { SuggestionFormComponent } from './suggestion-form.component';
import { IonicModule } from '@ionic/angular';
import { LoginPageWrapperModule } from '@app/modules/login-page-wrapper/login-page-wrapper.module';
import { FormErrorPipe } from '@app/modules/form-error-pipe/form-error.pipe';
import { DirectivesModule } from '@app/modules/directives.module';
import { WysiwygEditorComponent } from '@app/modules/wysiwyg-editor/wysiwyg-editor.component';
import { SuggestionApiService } from '@app/core/service/suggestion-api.service';
import { UploadFileApiService } from '@app/core/service/upload-file-api.service';
import { FilterUsersPipe } from './filter-users.pipe';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: SuggestionFormComponent
      }
    ]),
    CommonModule,
    ReactiveFormsModule,
    FormErrorWrapperModule,
    IonicModule,
    LoginPageWrapperModule,
    FormErrorPipe,
    DirectivesModule,
    WysiwygEditorComponent,
    FilterUsersPipe,
    FormsModule
  ],
  declarations: [SuggestionFormComponent],
  providers: [SuggestionApiService, UploadFileApiService]
})
export class SuggestionFormModule {}
