<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-title class="ion-text-capitalize">
      {{ taskTitle }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div>
  <ion-grid [formGroup]="form">
    <ion-row class="ion-justify-content-center">
      <ion-col size="8">
        <app-wide-button
          (buttonClicked)="userSelectorModal.present()"
          label="Completed By"
        >
          {{ currentUser.firstName }} {{ currentUser.lastName }}
        </app-wide-button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center">
      <ion-col size="8">
        <app-date-selector
          label="Date Completed"
          formControlName="dateCompleted"
        >
        </app-date-selector>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center">
      <ion-col size="8">
        <app-wide-button
          label="Quantity"
          [overflow]="false"
          (buttonClicked)="quantityInput.setFocus()"
        >
          <ion-input
            #quantityInput
            class="quantity-input"
            formControlName="quantity"
            inputmode="numeric"
            [maxlength]="6"
            type="text"
            digitOnly
            pattern="[1-9][0-9]*"
          ></ion-input>
        </app-wide-button>
      </ion-col>
    </ion-row>
    <ion-row
      class="ion-justify-content-center"
      [attr.data-hidden]="!showTimeAttackBonusPoints"
    >
      <ion-col size="8">
        <app-wide-button
          label="Time Attack Bonus Points"
          [overflow]="false"
          (buttonClicked)="timeAttackBonusPointsInput.setFocus()"
        >
          <ion-input
            #timeAttackBonusPointsInput
            class="quantity-input"
            formControlName="timeAttackBonusPoints"
            inputmode="numeric"
            [maxlength]="6"
            type="text"
            digitOnly
            pattern="[1-9][0-9]*"
          ></ion-input>
        </app-wide-button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-padding">
      <ion-col size="4">
        <ion-button
          *ngIf="!initialData.isDeleted"
          type="submit"
          color="danger"
          expand="block"
          fill="outline"
          (click)="onDeleteClick()"
          appHaptics
        >
          Delete
        </ion-button>
        <ion-button
          *ngIf="initialData.isDeleted"
          type="submit"
          color="dark"
          expand="block"
          fill="outline"
          (click)="onRestoreClick()"
          appHaptics
        >
          Restore
        </ion-button>
      </ion-col>
      <ion-col size="4">
        <ion-button
          type="submit"
          color="dark"
          expand="block"
          [disabled]="!canSave"
          (click)="onSaveClick()"
          appHaptics
          [disableHaptics]="!canSave"
        >
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<ion-modal
  #userSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      title="Completed By"
      class="ion-page"
      [users]="allUsers"
      [recipients]="selectedUserIds"
      (selectionChange)="onUserChange($event)"
      (selectionCancel)="userSelectorModal.dismiss()"
    ></app-user-selector>
  </ng-template>
</ion-modal>
