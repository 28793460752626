<div [formGroup]="scheduleFormGroup">
  <ion-row>
    <ion-col size="12">
      <ion-segment formControlName="scheduleType">
        <ion-segment-button [value]="userScheduleType.FIXED">
          <ion-label>Fixed Schedule</ion-label>
        </ion-segment-button>
        <ion-segment-button [value]="userScheduleType.VARIABLE">
          <ion-label>Variable Schedule</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-col>
  </ion-row>
  <div *ngIf="isFixedScheduleType; else variableScheduleInfo">
    <ion-row>
      <ion-col size="12">
        <app-schedule-weekly [form]="scheduleFormGroup"></app-schedule-weekly>
      </ion-col>
    </ion-row>
    <div [formGroup]="weekTimeFormGroup">
      <ion-row
        *ngFor="let day of dayArray; let i = index; trackBy: trackByDay"
        formGroupName="{{ i }}"
        class="weekday-day"
        [attr.data-hidden]="!scheduleFormGroup.value.weekDays[i]"
      >
        <ion-col size="2">
          <div class="weekday-name">
            {{ weekDays[i] }}
          </div>
        </ion-col>
        <ion-col size="4">
          <app-time-form-control
            class="app-w100"
            formControlName="from"
            id="from{{ i }}"
            label="From"
          ></app-time-form-control>
        </ion-col>
        <ion-col size="4">
          <app-time-form-control
            class="app-w100"
            formControlName="to"
            id="to{{ i }}"
            label="To"
          ></app-time-form-control>
        </ion-col>
        <ion-col size="2"></ion-col>
      </ion-row>
    </div>
  </div>
</div>

<ng-template #variableScheduleInfo>
  <div
    *ngIf="lastVariableShiftTime && !lastVariableShiftTime.isNotWorkingToday"
    class="last-variable-shift-time"
  >
    The last shift time was set to
    {{ lastVariableShiftTime.start | formatTime : '' }} -
    {{ lastVariableShiftTime.end | formatTime : '' }}
    on {{ lastVariableShiftTime.date | date }}
  </div>
  <div
    *ngIf="lastVariableShiftTime && lastVariableShiftTime.isNotWorkingToday"
    class="last-variable-shift-time"
  >
    {{ userId ? 'User' : 'You' }} answered as not working on
    {{ lastVariableShiftTime.date | date }}
  </div>
</ng-template>
